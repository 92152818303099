import { COLORS } from '@global/styles/colors';
import { FONT } from '@global/styles/default';
import { DESKTOP_1024, DESKTOP_1280, TABLET_768, TABLET_992 } from '@global/styles/sizes';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding-bottom: 44px;
  display: flex;
  flex-direction: column;
  background-color: #000000;
`;

export const TitleContainer = styled.h1`
  margin: 19px 0 32px;
  font-family: ${FONT.ulmGrotesk};
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  color: ${COLORS.white};
`;

export const TrainingContainer = styled.div`
  width: 59%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 26px;

  @media (max-width: ${TABLET_992}px) {
    display: none;
  }
  @media (max-width: ${TABLET_768}px) {
    width: 100%;
    display: flex;
  }
`;

export const TrainingWrapper = styled.a<{ isLastTraining?: boolean }>`
  width: 48.3%;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  box-shadow: 0px 0px 0px 1px ${COLORS.white};
  border-radius: 8px;

  &:hover {
    .data-container {
      background-color: #e2e8f0;
    }
  }

  @media (max-width: ${DESKTOP_1280}px) {
    width: 48%;
  }

  @media (max-width: ${TABLET_992}px) {
    width: 100%;
    grid-column-start: 1;
    ${({ isLastTraining }) =>
      isLastTraining &&
      css`
        grid-column-start: 2;
      `}
  }
  @media (max-width: ${TABLET_768}px) {
    width: 100%;
  }
`;

export const TrainingTitleTooltip = styled.div`
  width: 100%;
  padding: 10px;
  bottom: 54%;
  left: 10%;
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
  text-decoration: none;
  background-color: ${COLORS.white};
`;

export const TrainingTitleContainer = styled.div`
  padding-top: 10px;
  position: relative;

  &:hover {
    .training-title-tooltip {
      display: flex;
    }
  }
`;

export const TrainingTitle = styled.div`
  display: flex;
  align-items: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 20px;
  line-height: 31px;
  color: #000000;
  position: relative;

  @media (max-width: ${TABLET_992}px) {
    line-height: 24px;
  }
`;

export const DataContainer = styled.div`
  width: 50%;
  padding: 16px;
  border-radius: 8px 0 0 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: ${COLORS.white};
  position: relative;
`;

export const ImageContainer = styled.div`
  width: 60%;
  height: 100%;
  border-radius: 0 8px 8px 0;

  img {
    height: 100% !important;
    object-position: left;
    border-radius: 0 8px 8px 0;
    object-fit: cover;
  }
`;

export const AllTrainingsContainer = styled.div`
  width: 365px;
  padding: 28px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.violetRed};

  @media (max-width: ${TABLET_992}px) {
    height: max-content;
    grid-column-start: 2;
    grid-row-end: 2;
  }

  @media (max-width: ${TABLET_768}px) {
    width: 100%;
    box-sizing: border-box;
  }
`;

export const AllTrainingTitle = styled.div`
  margin-top: 15px;
  font-family: 'Poppins';
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: ${COLORS.white};
`;

export const AllTrainingText = styled.div`
  font-family: 'Poppins';
  font-size: 16px;
  line-height: 24px;
  color: ${COLORS.white};
`;

export const HeroWrapper = styled.div`
  height: 100%;
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;
  gap: 25px;

  @media (max-width: ${TABLET_992}px) {
    display: none;
  }

  @media (max-width: ${TABLET_768}px) {
    display: flex;
    flex-direction: column;
  }
`;

export const AllTrainingCTA = styled.a`
  width: 100%;
  height: 52px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  text-decoration: none;
  align-items: center;
  text-decoration: none;
  border-radius: 8px;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.violetRed};
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: ${COLORS.violetRed};

  &:hover {
    color: ${COLORS.white};
    background-color: ${COLORS.violetRed};
    border: 1px solid ${COLORS.white};
  }
`;

export const CalendarContainer = styled.img`
  width: 92px;
  height: 96px;
  margin: 0 auto;
`;

export const TrainingSchedule = styled.div`
  height: 37px;
  padding-bottom: 10px;
  display: flex;
  align-items: flex-end;
  color: ${COLORS.resolutionBlue};
  font-size: 36px;
  line-height: 32px;
  font-family: 'Poppins';
  font-weight: Bold;
  border-bottom: 1px solid #d5d5d5;

  @media (max-width: ${DESKTOP_1024}px) {
    padding-bottom: 5px;
  }

  @media (max-width: ${TABLET_992}px) {
    height: 35px;
  }
`;

export const SeparateLine = styled.div`
  margin: 0 5px;
  font-size: 16px;
  line-height: 16px;
  color: ${COLORS.violetRed};
`;

export const TrainingDate = styled.p`
  margin-left: 5px;
  display: flex;
  align-items: flex-start;
  font-size: 16px;
  line-height: 21px;
  font-weight: normal;
  font-family: 'Poppins';
  color: ${COLORS.resolutionBlue};
`;

export const TabletWrapper = styled.div`
  display: none;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 138px);
  gap: 20px;

  @media (max-width: ${TABLET_992}px) {
    display: grid;
  }
  @media (max-width: ${TABLET_768}px) {
    display: none;
  }
`;
