import React from 'react';
import { GlobalWrapper } from '@global/styles/grid';
import { Title, Wrapper, Text } from './noResultFilter.styles';

const NoResultFilter = () => {
  return (
    <GlobalWrapper>
      <Wrapper>
        <Title>No results to show.</Title>
        <Text>Please check back soon or explore other categories that might interest you.</Text>
      </Wrapper>
    </GlobalWrapper>
  );
};

export default NoResultFilter;
