import { FONT } from '@global/styles/default';
import { TABLET_992 } from '@global/styles/sizes';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 170px;
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  background-color: #f2f7fb;

  @media (max-width: ${TABLET_992}px) {
    height: 100%;
  }
`;

export const Title = styled.div`
  font-family: ${FONT.ulmGrotesk};
  font-size: 28px;
  line-height: 36px;
  font-weight: bold;
  color: #000000;
`;

export const Text = styled.div`
  font-family: 'Poppins';
  font-size: 20px;
  line-height: 24px;
  color: #000000;
`;
