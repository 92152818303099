import React, { useEffect, useState } from 'react';
import { GlobalWrapper } from '@global/styles/grid';
import { CMSParseImage } from '@components/StrapiComponents/ImageParser';
import { transformToSlug } from 'helpers/slugHelper';
import { PATHS } from '@global/constants/urls';
import NoResultFilter from '@components/NoResultFilter/NoResultFilter';
import CalendarIcon from './assets/calendar.svg';
import {
  Container,
  DataContainer,
  ImageContainer,
  SubtitleContainer,
  Tag,
  TitleContainer,
  Wrapper,
  AllWebinarContainer,
  AllWebinarTitle,
  SeparateLine,
  CalendarContainer,
  AllWebinarText,
  AllWebinarCTA,
  WebinarTitleContainer,
  WebinarSchedule,
  WebinarDate,
  WebinarTitleTooltip,
  WebinarContainer,
  CollectionClearFilter,
} from './webinarList.styles';
import { WebinarPostsListProps, WebinarsProps } from './WebinarList.types';

const WebinarPostsList = ({ webinars }: WebinarPostsListProps) => {
  const [renderTooltip, setRenderTooltip] = useState<string[]>();
  const [filterApplied, setFilterApplied] = useState<string[]>([]);
  const [renderWebinars, setRenderWebinars] = useState(webinars);

  const handleSchedule = (schedule: string) => {
    const scheduleDate = new Date(schedule);
    const scheduleHours = scheduleDate.getHours();
    const scheduleMinutes = scheduleDate.getMinutes();
    const scheduleWeekDate = scheduleDate.toLocaleString('en-us', { weekday: 'long' });

    if (!Number.isNaN(scheduleHours) && !Number.isNaN(scheduleMinutes)) {
      return (
        <>
          {scheduleDate.getDate()}
          <WebinarDate>
            {scheduleDate
              .toLocaleString('default', {
                month: 'short',
              })
              .toUpperCase()}
            <SeparateLine>|</SeparateLine>
            {scheduleWeekDate}
            <SeparateLine>|</SeparateLine>
            {scheduleHours > 10 ? scheduleHours : `0${scheduleHours}`}:
            {scheduleMinutes > 10 ? scheduleMinutes : `0${scheduleMinutes}`}
          </WebinarDate>
        </>
      );
    }
    return <WebinarDate>{schedule}</WebinarDate>;
  };

  const handleFilterTrainings = (arrayWebinars: WebinarsProps[]) => {
    const trainingsToRender: WebinarsProps[] = [];
    arrayWebinars.forEach((webinar) => {
      if (webinar.attributes.Topics.data.length > 0) {
        webinar.attributes.Topics.data.forEach((topic) => {
          if (filterApplied.includes(topic.attributes.Topic)) {
            trainingsToRender.push(webinar);
          }
        });
      }
      if (webinar.attributes.Roles.data.length > 0) {
        webinar.attributes.Roles.data.forEach((role) => {
          if (filterApplied.includes(role.attributes.Role)) {
            trainingsToRender.push(webinar);
          }
        });
      }
      if (webinar.attributes.Pathful_Modules.data.length > 0) {
        webinar.attributes.Pathful_Modules.data.forEach((career) => {
          if (filterApplied.includes(career.attributes.Pathful_Module)) {
            trainingsToRender.push(webinar);
          }
        });
      }
    });

    setRenderWebinars([...new Set(trainingsToRender)]);
  };

  const handleFilterViaQueryParam = (urlParams: URLSearchParams) => {
    const topic = urlParams.get('topic');
    const role = urlParams.get('role');
    const career = urlParams.get('career');
    if (topic) {
      setFilterApplied([topic]);
    }
    if (role) {
      setFilterApplied([role]);
    }
    if (career) {
      setFilterApplied([career]);
    }
  };

  useEffect(() => {
    if (filterApplied.length > 0) {
      handleFilterTrainings(webinars);
    } else {
      setRenderWebinars(webinars);
    }
  }, [webinars, filterApplied]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    handleFilterViaQueryParam(urlParams);

    const isEllipsisActive = (element) => {
      return element.offsetHeight + 2 < element.scrollHeight;
    };

    const elements = document.querySelectorAll('.training-title');
    const renderTooltipTitle: string[] = [];
    elements.forEach((el) => {
      if (isEllipsisActive(el)) {
        renderTooltipTitle.push(el.innerText);
      }
    });
    setRenderTooltip(renderTooltipTitle);
  }, []);
  return (
    <GlobalWrapper>
      <Container>
        {filterApplied.length > 0 && (
          <CollectionClearFilter onClick={() => setFilterApplied([])}>
            Clear Filter
          </CollectionClearFilter>
        )}
        <Wrapper>
          {renderWebinars.length > 0 ? (
            renderWebinars.map((webinarPosts, index) => (
              <>
                <WebinarContainer
                  key={webinarPosts.attributes.Title}
                  href={`${PATHS.WEBINAR}/${transformToSlug(
                    webinarPosts.attributes.Title.toLowerCase(),
                  )}`}
                >
                  <ImageContainer>
                    <Tag>Webinar</Tag>
                    {CMSParseImage(webinarPosts.attributes.Small_Image.data)}
                  </ImageContainer>
                  <DataContainer className="training-data">
                    <WebinarSchedule>
                      {handleSchedule(webinarPosts.attributes.Schedule)}
                    </WebinarSchedule>
                    <WebinarTitleContainer>
                      <TitleContainer className="training-title">
                        {webinarPosts.attributes.Title}
                      </TitleContainer>
                      {renderTooltip?.includes(webinarPosts.attributes.Title) && (
                        <WebinarTitleTooltip className="training-title-tooltip">
                          {webinarPosts.attributes.Title}
                        </WebinarTitleTooltip>
                      )}
                    </WebinarTitleContainer>
                    <SubtitleContainer>{webinarPosts.attributes.Subtitle}</SubtitleContainer>
                  </DataContainer>
                </WebinarContainer>
                {index === 4 && (
                  <AllWebinarContainer>
                    <CalendarContainer src={CalendarIcon} />
                    <AllWebinarTitle>Attend a Live, Virtual Webinar</AllWebinarTitle>
                    <AllWebinarText>Check out our webinar calendar and register.</AllWebinarText>
                    <AllWebinarCTA href={`${PATHS.CALENDAR}?type=webinar`}>
                      View all calendar
                    </AllWebinarCTA>
                  </AllWebinarContainer>
                )}
              </>
            ))
          ) : (
            <NoResultFilter />
          )}
        </Wrapper>
      </Container>
    </GlobalWrapper>
  );
};

export default WebinarPostsList;
