import React, { useEffect, useState } from 'react';
import { GlobalWrapper } from '@global/styles/grid';
import { StaticQuery, graphql } from 'gatsby';
import { CMSParseImage } from '@components/StrapiComponents/ImageParser';
import { transformToSlug } from 'helpers/slugHelper';
import { PATHS } from '@global/constants/urls';
import { WebinarIndexHeroProps } from './webinarIndexHero.types';
import CalendarIcon from './assets/calendar.svg';

import {
  TrainingContainer,
  TrainingTitle,
  TrainingWrapper,
  Container,
  DataContainer,
  ImageContainer,
  TitleContainer,
  AllTrainingsContainer,
  AllTrainingTitle,
  AllTrainingText,
  AllTrainingCTA,
  HeroWrapper,
  CalendarContainer,
  TrainingSchedule,
  SeparateLine,
  TrainingDate,
  TabletWrapper,
  TrainingTitleTooltip,
  TrainingTitleContainer,
} from './webinarIndexHero.styles';

const WebinarIndexHeroComponent = ({ data }: WebinarIndexHeroProps) => {
  const [renderTooltip, setRenderTooltip] = useState<string[]>();

  const spotlightTrainings = data.cms.webinarPosts.data;
  const handleSchedule = (schedule: string) => {
    const scheduleDate = new Date(schedule);
    const scheduleHours = scheduleDate.getHours();
    const scheduleMinutes = scheduleDate.getMinutes();
    if (!Number.isNaN(scheduleHours) && !Number.isNaN(scheduleMinutes)) {
      return (
        <>
          {scheduleDate.getDate()}
          <TrainingDate>
            {scheduleDate
              .toLocaleString('default', {
                month: 'short',
              })
              .toUpperCase()}
            <SeparateLine>|</SeparateLine>
            {scheduleHours > 10 ? scheduleHours : `0${scheduleHours}`}:
            {scheduleMinutes > 10 ? scheduleMinutes : `0${scheduleMinutes}`}
          </TrainingDate>
        </>
      );
    }
    return <TrainingDate>{schedule}</TrainingDate>;
  };

  const allTrainingBox = () => {
    return (
      <AllTrainingsContainer>
        <CalendarContainer src={CalendarIcon} />
        <AllTrainingTitle>Attend a Live, Virtual Webinar</AllTrainingTitle>
        <AllTrainingText>Check out our training calendar and register.</AllTrainingText>
        <AllTrainingCTA href={`${PATHS.CALENDAR}?type=webinar`}>View all calendar</AllTrainingCTA>
      </AllTrainingsContainer>
    );
  };

  useEffect(() => {
    const isEllipsisActive = (element) => {
      return element.offsetHeight + 2 < element.scrollHeight;
    };

    const elements = document.querySelectorAll('.training-title');
    const renderTooltipTitle: string[] = [];
    elements.forEach((el) => {
      if (isEllipsisActive(el)) {
        renderTooltipTitle.push(el.innerText);
      }
    });
    setRenderTooltip(renderTooltipTitle);
  }, []);

  return (
    <Container>
      <GlobalWrapper>
        <TitleContainer>Webinar</TitleContainer>
        <HeroWrapper>
          <TrainingContainer>
            {spotlightTrainings.map((webinarPost) => (
              <TrainingWrapper
                href={`${PATHS.WEBINAR}/${transformToSlug(
                  webinarPost.attributes.Title.toLowerCase(),
                )}`}
              >
                <DataContainer className="data-container">
                  <TrainingSchedule>
                    {handleSchedule(webinarPost.attributes.Schedule)}
                  </TrainingSchedule>
                  <TrainingTitleContainer>
                    <TrainingTitle className="training-title">
                      {webinarPost.attributes.Title}
                    </TrainingTitle>
                    {renderTooltip?.includes(webinarPost.attributes.Title) && (
                      <TrainingTitleTooltip className="training-title-tooltip">
                        {webinarPost.attributes.Title}
                      </TrainingTitleTooltip>
                    )}
                  </TrainingTitleContainer>
                </DataContainer>
                <ImageContainer>{CMSParseImage(webinarPost.attributes.Small_Image)}</ImageContainer>
              </TrainingWrapper>
            ))}
          </TrainingContainer>
          {allTrainingBox()}
        </HeroWrapper>
        <TabletWrapper>
          {spotlightTrainings.map((webinarPost, index) => (
            <TrainingWrapper
              href={`${PATHS.WEBINAR}/${transformToSlug(
                webinarPost.attributes.Title.toLowerCase(),
              )}`}
              isLastTraining={
                index === spotlightTrainings.length - 1 && spotlightTrainings.length > 3
              }
            >
              <DataContainer className="data-container">
                <TrainingSchedule>
                  {handleSchedule(webinarPost.attributes.Schedule)}
                </TrainingSchedule>
                <TrainingTitle>{webinarPost.attributes.Title}</TrainingTitle>
              </DataContainer>
              <ImageContainer>{CMSParseImage(webinarPost.attributes.Small_Image)}</ImageContainer>
            </TrainingWrapper>
          ))}
          {allTrainingBox()}
        </TabletWrapper>
      </GlobalWrapper>
    </Container>
  );
};

const WebinarIndexHero = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          cms {
            webinarPosts(filters: { Spotlight_Webinar: { eq: true } }, pagination: { limit: 4 }) {
              data {
                attributes {
                  Title
                  Schedule
                  Small_Image {
                    data {
                      attributes {
                        alternativeText
                        url
                        mime
                        urlJSON
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => <WebinarIndexHeroComponent data={data} />}
    />
  );
};

export default WebinarIndexHero;
